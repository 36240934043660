import React, {Fragment, useState}  from "react";
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

const Formulario = ({crearCita}) => {

    //Crear State de Cita
    const [cita, actualizarCita] = useState({
        Paciente: '',
        Tutor: '',
        Fecha: '',
        Hora: '',
        Sintomas: ''
    });

    const [error, actualizarError] = useState(false)

    //Funcion cuando escriben 

    const handleChange = e => {
        actualizarCita({
            ...cita,
            [e.target.name] : e.target.value
        })
    }

    //Extraer Valores
    const {Paciente, Tutor, Fecha, Hora, Sintomas } = cita;

    //Cuando el usuario envia el Form
    const submitCita = e => {
        e.preventDefault();

        // Validar
        if(Paciente.trim() === '' || Tutor.trim() === '' || Fecha.trim() === '' || Hora.trim() === '' || Sintomas.trim() === ''){
            actualizarError(true);
            return;
        }

        //Eliminar el mensaje de error previo
        actualizarError(false);

        //Asignar un ID
        cita.id = uuidv4();

        //Crear la Cita
        crearCita(cita);

        //Reiniciar el form
        actualizarCita({
            Paciente: '',
            Tutor: '',
            Fecha: '',
            Hora: '',
            Sintomas: ''
        })
    }

    return ( 
        <Fragment>
            <h2>Crear Cita</h2>

            { error ? <p className="alerta-error"> Todos los campos son obligatorios </p>
            : null }

            <form
                onSubmit={submitCita}
            >
                <label>Nombre del Paciente</label>
                <input
                    type="text"
                    name="Paciente"
                    className="u-full-width"
                    placeholder="Nombre Paciente"
                    onChange={handleChange}
                    value={Paciente} 
                />
                <label>Nombre del Tutor</label>
                <input
                    type="text"
                    name="Tutor"
                    className="u-full-width"
                    placeholder="Nombre del Tutor" 
                    onChange={handleChange} 
                    value={Tutor} 
                />
                <label>Fecha de la Cita</label>
                <input
                    type="date"
                    name="Fecha"
                    className="u-full-width"
                    onChange={handleChange}
                    value={Fecha}  
                />
               <label>Hora de la Cita</label>
                <input
                    type="time"
                    name="Hora"
                    className="u-full-width"
                    onChange={handleChange} 
                    value={Hora} 
                />
                <label>Sintomas</label>
                <textarea
                    className="u-full-width"       
                    name="Sintomas"    
                    onChange={handleChange} 
                    value={Sintomas}      
                ></textarea>
                <button
                    type="submit"
                    className="u-full-width button-primary"
                    onChange={handleChange} 
                >Agregar</button>
            </form>
        </Fragment>
     );
}
 
Formulario.propTypes = {
    crearCita: PropTypes.func.isRequired
}

export default Formulario;